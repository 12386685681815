// This is a MERGE of branches and "locations" (we can find locations in Tango > Delivery points)
// Used to be returned by api/v1/lookup/branches and api/v1/lookup/locations (Delivery points)

// Objects ordered by name
// The openingTime and closingTimeFormat must be: HH:MM
export const branches = [
	{
		id: 13,
		deliveryPointId: 37,
		abbreviateCode: "AC",
		address: "165 Beach Road, Auckland City, Auckland, 1010",
		lat: -36.849943254539546,
		lng: 174.77416849765476,
		googleMapPlaceId: "ChIJZ2NGFudIDW0R7K5UuhMALDQ",
		city: "Auckland",
		closingTime: "17:00",
		code: "AKLC",
		country: "New Zealand",
		dailyRateForDisplay: null,
		email: "aucklandcity@gorentals.co.nz",
		freePhone: "+64 9 974 1598",
		isAllowedAfterHoursDroppedOff: false,
		isAllowedAfterHoursPickups: false,
		minutesFromPickupToBranch: null,
		name: "Auckland City",
		openingTime: "08:00",
		phone: "+64 9 974 1598",
		showOnWeb: true,
		slug: "auckland-city",
	},
	{
		id: 10,
		deliveryPointId: 29,
		abbreviateCode: "AA",
		address: "3 Joseph Hammond Place, Auckland Airport, Mangere, Auckland 2022",
		lat: -36.9975954915722,
		lng: 174.7843833988211,
		googleMapPlaceId: "ChIJZ2NGFudIDW0RKyvkyt5w-MU",
		city: "Auckland",
		closingTime: "17:00",
		code: "AKLA",
		country: "New Zealand",
		dailyRateForDisplay: null,
		email: "aucklandairport@gorentals.co.nz",
		freePhone: "+64 9 974 1598",
		isAllowedAfterHoursDroppedOff: true,
		isAllowedAfterHoursPickups: true,
		minutesFromPickupToBranch: 15,
		name: "Auckland Airport",
		openingTime: "08:00",
		phone: "+64 9 974 1598",
		showOnWeb: true,
		slug: "auckland-airport",
	},
	{
        id: 15,
        deliveryPointId: 44,
        abbreviateCode: "WK",
        address: "Unit 4, Matiatia Wharf, 6 Ocean View Road, Waiheke Island, Auckland, 1081",
		lat: -36.78038639298705,
		lng: 174.99236353613256,
		googleMapPlaceId: "ChIJWa4AJlDLcm0RXCKwAN1Iz9U",
        city: "Auckland",
        closingTime: "16:00",
        code: "WKE",
        country: "New Zealand",
        dailyRateForDisplay: null,
        email: "waiheke@gorentals.co.nz",
		freePhone: "+64 9 974 1598",
        isAllowedAfterHoursDroppedOff: true,
        isAllowedAfterHoursPickups: true,
        minutesFromPickupToBranch: 1,
        name: "Waiheke - Matiatia",
        openingTime: "08:00",
        phone: "+64 9 974 1598",
        showOnWeb: true,
        slug: "waiheke",
	},
	{
		id: 6,
		deliveryPointId: 48,
		abbreviateCode: "WG",
		address: "Terminal Building, Stewart Duff Drive, Rongotai, Wellington 6022",
		lat: -41.32943,
		lng: 174.81189,
		googleMapPlaceId: "ChIJ4Ruk_n2vOG0R4pib1k8eluY",
		city: "Wellington",
		closingTime: "01:00",
		code: "WLG",
		country: "New Zealand",
		dailyRateForDisplay: null,
		email: "wellington@gorentals.co.nz",
		freePhone: "+64 9 974 1598",
		isAllowedAfterHoursDroppedOff: true,
		isAllowedAfterHoursPickups: true,
		minutesFromPickupToBranch: 15,
		name: "Wellington Airport",
		openingTime: "07:00",
		phone: "+64 9 974 1598",
		showOnWeb: true,
		slug: "wellington",
	},
	{
		id: 8,
		deliveryPointId: 11,
		abbreviateCode: "NL",
		address: "Terminal Building Trent Drive, Nelson Airport, Nelson 7011",
		lat: -41.29957011608294,
		lng: 173.22687663814068,
		googleMapPlaceId: "ChIJ-Retn-GTO20RUOhY1htGymw",
		city: "Nelson",
		closingTime: "17:00",
		code: "NSN",
		country: "New Zealand",
		dailyRateForDisplay: null,
		email: "nelson@gorentals.co.nz",
		freePhone: "+64 9 974 1598",
		isAllowedAfterHoursDroppedOff: true,
		isAllowedAfterHoursPickups: true,
		minutesFromPickupToBranch: 1,
		name: "Nelson Airport",
		openingTime: "08:00",
		phone: "+64 9 974 1598",
		showOnWeb: true,
		slug: "nelson",
	},
	{
		id: 7,
		deliveryPointId: 7,
		abbreviateCode: "CC",
		address: "155 Orchard Road, Christchurch Airport, Harewood, Christchurch 8051",
		lat: -43.4760360535395,
		lng: 172.55382189702308,
		googleMapPlaceId: "ChIJ71pCebv0MW0RcJGUwDb-F18",
		city: "Christchurch",
		closingTime: "17:00",
		code: "CHC",
		country: "New Zealand",
		dailyRateForDisplay: null,
		email: "christchurch@gorentals.co.nz",
		freePhone: "+64 9 974 1598",
		isAllowedAfterHoursDroppedOff: true,
		isAllowedAfterHoursPickups: true,
		minutesFromPickupToBranch: 10,
		name: "Christchurch Airport",
		openingTime: "08:00",
		phone: "+64 9 974 1598",
		showOnWeb: true,
		slug: "christchurch",
	},
	{
		id: 11,
		deliveryPointId: 24,
		abbreviateCode: "ZN",
		address: "Sir Henry Wigley Drive, Frankton, Queenstown, 9300",
		lat: -45.02206822730308,
		lng: 168.73725062988,
		googleMapPlaceId: "ChIJLUt3T0ce1akRqGl8Hw7vwmg",
		city: "Queenstown",
		closingTime: "17:00",
		code: "ZQN",
		country: "New Zealand",
		dailyRateForDisplay: null,
		email: "queenstown@gorentals.co.nz",
		freePhone: "+64 9 974 1598",
		isAllowedAfterHoursDroppedOff: true,
		isAllowedAfterHoursPickups: true,
		minutesFromPickupToBranch: 10,
		name: "Queenstown Airport",
		openingTime: "08:00",
		phone: "+64 9 974 1598",
		showOnWeb: true,
		slug: "queenstown",
	},
	{
		id: 14,
		deliveryPointId: 43,
		abbreviateCode: "DD",
		address: "Terminal Building, Dunedin Airport, Dunedin 9073",
		lat: -45.92410016610155,
		lng: 170.19917297844782,
		googleMapPlaceId: "ChIJWWzO4YoFLKgR6N6iBmfWBpY",
		city: "Dunedin",
		closingTime: "17:00",
		code: "DUD",
		country: "New Zealand",
		dailyRateForDisplay: null,
		email: "dunedin@gorentals.co.nz",
		freePhone: "+64 9 974 1598",
		isAllowedAfterHoursDroppedOff: true,
		isAllowedAfterHoursPickups: true,
		minutesFromPickupToBranch: null,
		name: "Dunedin Airport",
		openingTime: "08:00",
		phone: "+64 9 974 1598",
		showOnWeb: true,
		slug: "dunedin",
	},
	{
		id: 16,
		deliveryPointId: 51,
		abbreviateCode: "IV",
		address: "Terminal Building, 106 Airport Avenue, Invercargill 9810",
		lat: -46.415596,
		lng: 168.321762,
		googleMapPlaceId: "ChIJocXF6UjD0qkROl2eeJwjG5E",
		city: "Invercargill",
		openingTime: "08:00",
		closingTime: "17:00",
		code: "IVC",
		country: "New Zealand",
		dailyRateForDisplay: null,
		email: "invercargill@gorentals.co.nz",
		isAllowedAfterHoursDroppedOff: true,
		isAllowedAfterHoursPickups: true,
		minutesFromPickupToBranch: null,
		name: "Invercargill Airport",
		phone: "+64 9 974 1598",
		freePhone: "+64 9 974 1598",
		showOnWeb: true,
		slug: "invercargill-airport",
	},
];
