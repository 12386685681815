export default (context) => {
	const itinerary = context.app.context.app.$sessionStorageService.get("itinerary")
	const reservation = context.app.context.app.$sessionStorageService.get("reservation")
	const reservationExtras = context.app.context.app.$sessionStorageService.get("reservation-extras")
	const selectedVehicle = context.app.context.app.$sessionStorageService.get("selectedVehicle")
	const tokenInfo = context.app.context.app.$cookies.get("tokenInfo")
	const userInfo = context.app.context.app.$cookies.get("user")

	if (itinerary) {
		itinerary.pickup.date = !itinerary.pickup.date ? null : new Date(itinerary.pickup.date)
		itinerary.dropOff.date = !itinerary.dropOff.date ? null : new Date(itinerary.dropOff.date)
		context.store.commit("itinerary/updateItinerary", itinerary)
	}

	if (selectedVehicle) {
		context.store.commit("vehicle/updateSelectedVehicle", selectedVehicle)
	}

	if (reservation) {
		reservation.extras = !reservationExtras ? [] : reservationExtras
		context.store.commit("booking/updateReservation", reservation)
	}
	if (tokenInfo) {
		context.store.commit("authentication/updateToken", tokenInfo)
	}

	if (userInfo) {
		context.store.commit("authentication/updateUser", userInfo)
		if (!process.server && context.store.state.authentication?.tokenInfo?.token) {
			context.store.dispatch("authentication/scheduleRenewal")
			context.store.dispatch("authentication/getDashboardData")
		}
	}
}
